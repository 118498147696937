export default {
    codes: [
        {
            code: '99',
            text: 'Registro recebido e incluído com sucesso'
        },
        {
            code: '00',
            text: 'Cobrança arrecadada'
        },
        {
            code: '01',
            text: 'Cancelada parcela de cobrança depois de emitida fatura de energia'
        },
        {
            code: '02',
            text: 'Cancelada parcela de cobrança antes de emitida fatura de energia'
        },
        {
            code: '03',
            text: 'Cancelada parcela de cobrança em virtude de unidade consumidora desligada'
        },
        {
            code: '04',
            text: 'Código de movimento inválido'
        },
        {
            code: '05',
            text: 'Data de liberação de cobrança para faturamento inválida'
        },
        {
            code: '06',
            text: 'Cliente inválido'
        },
        {
            code: '07',
            text: 'Cliente sem unidade consumidora'
        },
        {
            code: '08',
            text: 'Unidade consumidora desligada'
        },
        {
            code: '09',
            text: 'Código do convênio inválido'
        },
        {
            code: '10',
            text: 'Código do produto inválido'
        },
        {
            code: '11',
            text: 'Valor da parcela inválido'
        },
        {
            code: '12',
            text: 'Valor da moeda inválido'
        },
        {
            code: '13',
            text: 'Problemas com inc/alt/canc cobrança'
        },
        {
            code: '14',
            text: 'Número da parcela inválido'
        },
        {
            code: '15',
            text: 'Valor devolvido ao cliente'
        },
        {
            code: '16',
            text: 'Cliente bloqueado'
        },
        {
            code: '17',
            text: 'Cobranças/parcelas canceladas antes do faturamento pela COPEL DISTRIBUIÇÃO'
        },
        {
            code: '18',
            text: 'Alteração do responsável pela unidade consumidora'
        },
        {
            code: '20',
            text: 'Cobrança não autorizada'
        },
        {
            code: '21',
            text: 'Novo morador'
        },
        {
            code: '22',
            text: 'Já solicitado ao contratante'
        },
        {
            code: '23',
            text: 'Exclusão só nesta fatura'
        },
        {
            code: '24',
            text: 'Valor difere do contratado'
        },
        {
            code: '25',
            text: 'Cliente desistiu'
        },
        {
            code: '88',
            text: 'Estorno de parcelas'

        },
        {
            code: '89',
            text: 'Cobrança só faturada'
        },
        {
            code: '90',
            text: 'Cobrança faturada e arrecadada'
        },
        {
            code: '91',
            text: 'Cobrança faturada e cancelada'
        },
    ]
}