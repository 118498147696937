<template>
  <div class="detailed-return">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <div class="table-header d-flex justify-content-between">
                <input
                  type="text"
                  placeholder="Pesquisar"
                  v-model="filterTerm"
                  v-on:keyup="filterTable()"
                  class="form-control"
                />
              </div>
              <b-table
                hover
                no-local-sorting
                :fields="fields"
                :items="data"
                :per-page="0"
                :current-page="currentPage"
              >
                <template v-slot:cell(billing_date)="data">{{
                  formatDate(data.value)
                }}</template>
                <template v-slot:cell(code)="data">{{
                  formatCode(data.value)
                }}</template>
              </b-table>
              <div class="no-data" v-if="data.length == 0">
                <span>Sem dados</span>
              </div>
              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";
import { bus } from "@/main";
import return_codes from "@/imports/ReturnCodes";

export default {
  name: "detailed-return",
  props: {
    id: Number,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      data: [],
      filter: {
        name: "",
      },
      includes: ["shipmentReturn", "client", "wallet"],
      sortBy: "",
      filterTerm: "",
      fields: [
        {
          key: "client.name",
          label: "Cliente",
          class: "client-name-cell",
        },
        {
          key: "wallet.name",
          label: "Carteira",
          class: "return-cell",
        },
        {
          key: "client.consumer_unit",
          label: "Unidade Consumidora",
          class: "return-cell",
        },
        {
          key: "billing_date",
          label: "Data de Faturamento",
          class: "billing_date-cell",
        },
        {
          key: "code",
          label: "Baixado",
          class: "downloaded-cell",
        },
      ],
      navigate_items: [
        {
          text: "Financeiro",
          active: true,
        },
      ],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.providerData();
      },
    },
  },
  mounted() {
    this.providerData();
  },
  methods: {
    providerData() {
      helper
        .getPaginateQuery(
          "shipments-clients",
          helper.getUrlBuildQuery(
            this.perPage,
            this.currentPage,
            this.filter,
            this.includes,
            this.sortBy
          )
        )
        .then((response) => {
          this.rows = response.data.total;
          this.data = response.data.data;
        });
    },
    filterTable: function () {
      this.filter.filter = this.filterTerm;
      this.currentPage = 1;
      this.providerData();
    },
    formatDate: function (date) {
      let newData = helper.formatDateOnly(date);
      newData == "Invalid date"
        ? (newData = "")
        : (newData = helper.formatDateOnly(date));
      return newData;
    },
    formatCode: function (data) {
      let status = data == "00" ? "Sim" : "Não";
      return status;
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
.list-clients {
  .name-cell {
    width: 40%;
  }
  .cpf-cell {
    width: 20%;
  }
  .phone-cell {
    width: 20%;
  }
  .date-cell {
    width: 20%;
  }
}
</style>